import React, { useEffect, useState } from "react";
import {
  allLetters,
  allLetersBasic,
  allNumbers,
  qa,
} from "../constant/allLeters";
import LetterCss from "./letter.css";

const Letters = () => {
  const [letters, setLetters] = useState(allLetters);
  const [basicLetters, setbasicLetters] = useState(allLetersBasic);

  const playAudio = (fileName) => {
    const url = `https://kids-speech.s3.ap-south-1.amazonaws.com/letters/${fileName.trim()}.mp3`;
    console.log("url is ...");
    const audio = new Audio(url);
    audio.play();
  };

  const autoPlay = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      setTimeout(() => {
        playAudio(arr[i].marathi);
      }, 1000 * i);
    }
  };

  const autoPlayNumber = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      setTimeout(() => {
        playNumber(arr[i].number.toString());
      }, 1000 * i);
    }
  };

  const playNumber = (fileName) => {
    const url = `https://kids-speech.s3.ap-south-1.amazonaws.com/numbers/${fileName.trim()}.mp3`;
    console.log("url is ...");
    const audio = new Audio(url);
    audio.playbackRate = 0.7;
    audio.play();
  };

  const playQA = (fileName) => {
    const url = `https://kids-speech.s3.ap-south-1.amazonaws.com/Question and Answers/${fileName.trim()}.mp3`;
    console.log("url is ...");
    const audio = new Audio(url);
    audio.playbackRate = 0.8;
    audio.play();
  };

  return (
    <div>
    <div className="question-box">

        <button className="ans" onClick={()=>playQA('MySelf')}> My Self</button>

    {qa.map((item, key) => (
        <div >
          <div className="question" >
            <span onClick={() => playQA(item.question)}> {item.question}</span>
            
          <button className="ans" onClick={() => playQA(item.ans)}>ANS</button>

          </div>

        </div>
      ))}


    </div>
      {allNumbers.map((item, key) => (
        <div className="main-box">
          <button onClick={() => autoPlayNumber(item)}>Play</button>
          {item.map((subItem, key) => (
            <div
              onClick={() => playNumber(subItem.value)}
              className="number-box"
            >
              {subItem.number}
            </div>
          ))}{" "}
        </div>
      ))}

      <div className="main-box">
        <button onClick={() => autoPlay(basicLetters)}>Play</button>
        {basicLetters.map((item, key) => (
          <div onClick={() => playAudio(item.marathi)} className="basic-box">
            {item.marathi}
          </div>
        ))}
      </div>
      {letters.map((item, key) => (
        <div className="main-box">
          {" "}
          <button onClick={() => autoPlay(item)}>Play</button>
          {item.map((subItem, key) => (
            <div onClick={() => playAudio(subItem.marathi)} className="box">
              {subItem.marathi}
            </div>
          ))}{" "}
        </div>
      ))}
    </div>
  );
};

export default Letters;
