const allLetters = [
  [
    {
      english: "ka",
      marathi: " क",
    },
    {
      english: "kaa",
      marathi: "का",
    },
    {
      english: "ki",
      marathi: "कि",
    },
    {
      english: "kee",
      marathi: "की",
    },
    {
      english: "ku",
      marathi: "कु",
    },
    {
      english: "koo",
      marathi: "कू",
    },
    {
      english: "ke",
      marathi: "के",
    },
    {
      english: "kai",
      marathi: "कै",
    },
    {
      english: "ko",
      marathi: "को",
    },
    {
      english: "kau",
      marathi: "कौ",
    },
    {
      english: "kam",
      marathi: "कं",
    },
    {
      english: "kaha",
      marathi: "कः",
    },
  ],
  [
    {
      english: "  ",
      marathi: " ख",
    },
    {
      english: "khaa",
      marathi: "खा",
    },
    {
      english: "khi",
      marathi: "खि",
    },
    {
      english: "khee",
      marathi: "खी",
    },
    {
      english: "khu",
      marathi: "खु",
    },
    {
      english: "khoo",
      marathi: "खू",
    },
    {
      english: "khe",
      marathi: "खे",
    },
    {
      english: "khai",
      marathi: "खै",
    },
    {
      english: "kho",
      marathi: "खो",
    },
    {
      english: "khau",
      marathi: "खौ",
    },
    {
      english: "kham",
      marathi: "खं",
    },
    {
      english: "khah",
      marathi: "खः",
    },
  ],
  [
    {
      english: "ga",
      marathi: " ग",
    },
    {
      english: "gaa",
      marathi: "गा",
    },
    {
      english: "gi",
      marathi: "गि",
    },
    {
      english: "gee",
      marathi: "गी",
    },
    {
      english: "gu",
      marathi: "गु",
    },
    {
      english: "goo",
      marathi: "गू",
    },
    {
      english: "ge",
      marathi: "गे",
    },
    {
      english: "gai",
      marathi: "गै",
    },
    {
      english: "go",
      marathi: "गो",
    },
    {
      english: "gau",
      marathi: "गौ",
    },
    {
      english: "gam",
      marathi: "गं",
    },
    {
      english: "gah",
      marathi: "गः",
    },
  ],
  [
    {
      english: "gha",
      marathi: " घ",
    },
    {
      english: "ghaa",
      marathi: "घा",
    },
    {
      english: "ghi",
      marathi: "घि",
    },
    {
      english: "ghee",
      marathi: "घी",
    },
    {
      english: "ghu",
      marathi: "घु",
    },
    {
      english: "ghoo",
      marathi: "घू",
    },
    {
      english: "ghe",
      marathi: "घे",
    },
    {
      english: "ghai",
      marathi: "घै",
    },
    {
      english: "gho",
      marathi: "घो",
    },
    {
      english: "ghau",
      marathi: "घौ",
    },
    {
      english: "gham",
      marathi: "घं",
    },
    {
      english: "ghah",
      marathi: "घः",
    },
  ],
  [
    {
      english: "cha",
      marathi: " च",
    },
    {
      english: "chaa",
      marathi: "चा",
    },
    {
      english: "chi",
      marathi: "चि",
    },
    {
      english: "chee",
      marathi: "ची",
    },
    {
      english: "chu",
      marathi: "चु",
    },
    {
      english: "choo",
      marathi: "चू",
    },
    {
      english: "che",
      marathi: "चे",
    },
    {
      english: "chai",
      marathi: "चै",
    },
    {
      english: "cho",
      marathi: "चो",
    },
    {
      english: "chau",
      marathi: "चौ",
    },
    {
      english: "cham",
      marathi: "चं",
    },
    {
      english: "chah",
      marathi: "चः",
    },
  ],
  [
    {
      english: "chha",
      marathi: " छ",
    },
    {
      english: "chhaa",
      marathi: "छा",
    },
    {
      english: "chhi",
      marathi: "छि",
    },
    {
      english: "chhee",
      marathi: "छी",
    },
    {
      english: "chhu",
      marathi: "छु",
    },
    {
      english: "chhoo",
      marathi: "छू",
    },
    {
      english: "chhe",
      marathi: "छे",
    },
    {
      english: "chhai",
      marathi: "छै",
    },
    {
      english: "chho",
      marathi: "छो",
    },
    {
      english: "chhou",
      marathi: "छौ",
    },
    {
      english: "chham",
      marathi: "छं",
    },
    {
      english: "chhah",
      marathi: "छः",
    },
  ],
  [
    {
      english: "ja",
      marathi: " ज",
    },
    {
      english: "jaa",
      marathi: "जा",
    },
    {
      english: "ji",
      marathi: "जि",
    },
    {
      english: "jee",
      marathi: "जी",
    },
    {
      english: "ju",
      marathi: "जु",
    },
    {
      english: "joo",
      marathi: "जू",
    },
    {
      english: "je",
      marathi: "जे",
    },
    {
      english: "jai",
      marathi: "जै",
    },
    {
      english: "jo",
      marathi: "जो",
    },
    {
      english: "jau",
      marathi: "जौ",
    },
    {
      english: "jam",
      marathi: "जं",
    },
    {
      english: "jah",
      marathi: "जः",
    },
  ],
  [
    {
      english: "jha",
      marathi: " झ",
    },
    {
      english: "jhaa",
      marathi: "झा",
    },
    {
      english: "jhi",
      marathi: "झि",
    },
    {
      english: "jhee",
      marathi: "झी",
    },
    {
      english: "jhu",
      marathi: "झु",
    },
    {
      english: "jhoo",
      marathi: "झू",
    },
    {
      english: "jhe",
      marathi: "झे",
    },
    {
      english: "jhai",
      marathi: "झै",
    },
    {
      english: "jho",
      marathi: "झो",
    },
    {
      english: "jhau",
      marathi: "झौ",
    },
    {
      english: "jham",
      marathi: "झं",
    },
    {
      english: "jhah",
      marathi: "झः",
    },
  ],
  [
    {
      english: "tra",
      marathi: " त्र",
    },
    {
      english: "traa",
      marathi: "त्रा",
    },
    {
      english: "tri",
      marathi: "त्रि",
    },
    {
      english: "tree",
      marathi: "त्री",
    },
    {
      english: "tru",
      marathi: "त्रु",
    },
    {
      english: "troo",
      marathi: "त्रू",
    },
    {
      english: "tre",
      marathi: "त्रे",
    },
    {
      english: "trai",
      marathi: "त्रै",
    },
    {
      english: "tro",
      marathi: "त्रो",
    },
    {
      english: "trau",
      marathi: "ञौ",
    },
    {
      english: "tram",
      marathi: "ञं",
    },
    {
      english: "trah",
      marathi: "ञः",
    },
  ],
  [
    {
      english: "ta",
      marathi: " ट",
    },
    {
      english: "taa",
      marathi: "टा",
    },
    {
      english: "ti",
      marathi: "टि",
    },
    {
      english: "tee",
      marathi: "टी",
    },
    {
      english: "tu",
      marathi: "टु",
    },
    {
      english: "too",
      marathi: "टू",
    },
    {
      english: "te",
      marathi: "टे",
    },
    {
      english: "tai",
      marathi: "टै",
    },
    {
      english: "to",
      marathi: "टो",
    },
    {
      english: "tau",
      marathi: "टौ",
    },
    {
      english: "tam",
      marathi: "टं",
    },
    {
      english: "tah",
      marathi: "टः",
    },
  ],
  [
    {
      english: "tha",
      marathi: " ठ",
    },
    {
      english: "thaa",
      marathi: "ठा",
    },
    {
      english: "thi",
      marathi: "ठि",
    },
    {
      english: "thee",
      marathi: "ठी",
    },
    {
      english: "thu",
      marathi: "ठु",
    },
    {
      english: "thoo",
      marathi: "ठू",
    },
    {
      english: "the",
      marathi: "ठे",
    },
    {
      english: "thai",
      marathi: "ठै",
    },
    {
      english: "tho",
      marathi: "ठो",
    },
    {
      english: "thau",
      marathi: "ठौ",
    },
    {
      english: "tham",
      marathi: "ठं",
    },
    {
      english: "thah",
      marathi: "ठः",
    },
  ],
  [
    {
      english: "da",
      marathi: " ड",
    },
    {
      english: "daa",
      marathi: "डा",
    },
    {
      english: "di",
      marathi: "डि",
    },
    {
      english: "dee",
      marathi: "डी",
    },
    {
      english: "du",
      marathi: "डु",
    },
    {
      english: "doo",
      marathi: "डू",
    },
    {
      english: "de",
      marathi: "डे",
    },
    {
      english: "dai",
      marathi: "डै",
    },
    {
      english: "do",
      marathi: "डो",
    },
    {
      english: "dau",
      marathi: "डौ",
    },
    {
      english: "dam",
      marathi: "डं",
    },
    {
      english: "dah",
      marathi: "डः",
    },
  ],
  [
    {
      english: "dha",
      marathi: " ढ",
    },
    {
      english: "dhaa",
      marathi: "ढा",
    },
    {
      english: "dhi",
      marathi: "ढि",
    },
    {
      english: "dhee",
      marathi: "ढी",
    },
    {
      english: "dhu",
      marathi: "ढु",
    },
    {
      english: "dhoo",
      marathi: "ढू",
    },
    {
      english: "dhe",
      marathi: "ढे",
    },
    {
      english: "dhai",
      marathi: "ढै",
    },
    {
      english: "dhao",
      marathi: "ढो",
    },
    {
      english: "dhau",
      marathi: "ढौ",
    },
    {
      english: "dham",
      marathi: "ढं",
    },
    {
      english: "dhah",
      marathi: "ढः",
    },
  ],
  [
    {
      english: "na",
      marathi: " ण",
    },
    {
      english: "naa",
      marathi: "णा",
    },
    {
      english: "ni",
      marathi: "णि",
    },
    {
      english: "nee",
      marathi: "णी",
    },
    {
      english: "nu",
      marathi: "णु",
    },
    {
      english: "noo",
      marathi: "णू",
    },
    {
      english: "ne",
      marathi: "णे",
    },
    {
      english: "nai",
      marathi: "णै",
    },
    {
      english: "no",
      marathi: "णो",
    },
    {
      english: "nau",
      marathi: "णौ",
    },
    {
      english: "nam",
      marathi: "णं",
    },
    {
      english: "nah",
      marathi: "णः",
    },
  ],
  [
    {
      english: "ta",
      marathi: " त",
    },
    {
      english: "taa",
      marathi: "ता",
    },
    {
      english: "ti",
      marathi: "ति",
    },
    {
      english: "tee",
      marathi: "ती",
    },
    {
      english: "tu",
      marathi: "तु",
    },
    {
      english: "too",
      marathi: "तू",
    },
    {
      english: "te",
      marathi: "ते",
    },
    {
      english: "tai",
      marathi: "तै",
    },
    {
      english: "to",
      marathi: "तो",
    },
    {
      english: "tau",
      marathi: "तौ",
    },
    {
      english: "tam",
      marathi: "तं",
    },
    {
      english: "tah",
      marathi: "तः",
    },
  ],
  [
    {
      english: "tha",
      marathi: " थ",
    },
    {
      english: "thaa",
      marathi: "था",
    },
    {
      english: "thi",
      marathi: "थि",
    },
    {
      english: "thee",
      marathi: "थी",
    },
    {
      english: "thu",
      marathi: "थु",
    },
    {
      english: "thoo",
      marathi: "थू",
    },
    {
      english: "the",
      marathi: "थे",
    },
    {
      english: "thai",
      marathi: "थै",
    },
    {
      english: "tho",
      marathi: "थो",
    },
    {
      english: "thau",
      marathi: "थौ",
    },
    {
      english: "tham",
      marathi: "थं",
    },
    {
      english: "thah",
      marathi: "थः",
    },
  ],
  [
    {
      english: "da",
      marathi: " द",
    },
    {
      english: "daa",
      marathi: "दा",
    },
    {
      english: "di",
      marathi: "दि",
    },
    {
      english: "dee",
      marathi: "दी",
    },
    {
      english: "du",
      marathi: "दु",
    },
    {
      english: "doo",
      marathi: "दू",
    },
    {
      english: "de",
      marathi: "दे",
    },
    {
      english: "dai",
      marathi: "दै",
    },
    {
      english: "do",
      marathi: "दो",
    },
    {
      english: "dau",
      marathi: "दौ",
    },
    {
      english: "dam",
      marathi: "दं",
    },
    {
      english: "dah",
      marathi: "दः",
    },
  ],
  [
    {
      english: "Dha",
      marathi: " ध",
    },
    {
      english: "dhaa",
      marathi: "धा",
    },
    {
      english: "dhi",
      marathi: "धि",
    },
    {
      english: "dhee",
      marathi: "धी",
    },
    {
      english: "dhu",
      marathi: "धु",
    },
    {
      english: "dhoo",
      marathi: "धू",
    },
    {
      english: "dhe",
      marathi: "धे",
    },
    {
      english: "dhai",
      marathi: "धै",
    },
    {
      english: "dho",
      marathi: "धो",
    },
    {
      english: "dhau",
      marathi: "धौ",
    },
    {
      english: "dham",
      marathi: "धं",
    },
    {
      english: "dhah",
      marathi: "धः",
    },
  ],
  [
    {
      english: "na",
      marathi: " न",
    },
    {
      english: "naa",
      marathi: "ना",
    },
    {
      english: "ni",
      marathi: "नि",
    },
    {
      english: "nee",
      marathi: "नी",
    },
    {
      english: "nu",
      marathi: "नु",
    },
    {
      english: "noo",
      marathi: "नू",
    },
    {
      english: "ne",
      marathi: "ने",
    },
    {
      english: "nai",
      marathi: "नै",
    },
    {
      english: "no",
      marathi: "नो",
    },
    {
      english: "nau",
      marathi: "नौ",
    },
    {
      english: "nam",
      marathi: "नं",
    },
    {
      english: "nah",
      marathi: "नः",
    },
  ],
  [
    {
      english: "pa",
      marathi: " प",
    },
    {
      english: "paa",
      marathi: "पा",
    },
    {
      english: "pi",
      marathi: "पि",
    },
    {
      english: "pee",
      marathi: "पी",
    },
    {
      english: "pu",
      marathi: "पु",
    },
    {
      english: "poo",
      marathi: "पू",
    },
    {
      english: "pe",
      marathi: "पे",
    },
    {
      english: "pai",
      marathi: "पै",
    },
    {
      english: "po",
      marathi: "पो",
    },
    {
      english: "pau",
      marathi: "पौ",
    },
    {
      english: "pam",
      marathi: "पं",
    },
    {
      english: "pah",
      marathi: "पः",
    },
  ],
  [
    {
      english: "pha",
      marathi: " फ",
    },
    {
      english: "phaa",
      marathi: "फा",
    },
    {
      english: "phi",
      marathi: "फि",
    },
    {
      english: "phee",
      marathi: "फी",
    },
    {
      english: "phu",
      marathi: "फु",
    },
    {
      english: "phoo",
      marathi: "फू",
    },
    {
      english: "phe",
      marathi: "फे",
    },
    {
      english: "pahi",
      marathi: "फै",
    },
    {
      english: "pho",
      marathi: "फो",
    },
    {
      english: "phau",
      marathi: "फौ",
    },
    {
      english: "pham",
      marathi: "फं",
    },
    {
      english: "phah",
      marathi: "फः",
    },
  ],
  [
    {
      english: "ba",
      marathi: " ब",
    },
    {
      english: "baa",
      marathi: "बा",
    },
    {
      english: "bi",
      marathi: "बि",
    },
    {
      english: "bee",
      marathi: "बी",
    },
    {
      english: "bu",
      marathi: "बु",
    },
    {
      english: "boo",
      marathi: "बू",
    },
    {
      english: "be",
      marathi: "बे",
    },
    {
      english: "bai",
      marathi: "बै",
    },
    {
      english: "bo",
      marathi: "बो",
    },
    {
      english: "bau",
      marathi: "बौ",
    },
    {
      english: "bam",
      marathi: "बं",
    },
    {
      english: "bah",
      marathi: "बः",
    },
  ],
  [
    {
      english: "bha",
      marathi: " भ",
    },
    {
      english: "bhaa",
      marathi: "भा",
    },
    {
      english: "bhi",
      marathi: "भि",
    },
    {
      english: "bhee",
      marathi: "भी",
    },
    {
      english: "bhu",
      marathi: "भु",
    },
    {
      english: "bhoo",
      marathi: "भू",
    },
    {
      english: "bhe",
      marathi: "भे",
    },
    {
      english: "bhai",
      marathi: "भै",
    },
    {
      english: "bho",
      marathi: "भो",
    },
    {
      english: "bhau",
      marathi: "भौ",
    },
    {
      english: "bham",
      marathi: "भं",
    },
    {
      english: "bhah",
      marathi: "भः",
    },
  ],
  [
    {
      english: "ma",
      marathi: " म",
    },
    {
      english: "maa",
      marathi: "मा",
    },
    {
      english: "mi",
      marathi: "मि",
    },
    {
      english: "mee",
      marathi: "मी",
    },
    {
      english: "mu",
      marathi: "मु",
    },
    {
      english: "moo",
      marathi: "मू",
    },
    {
      english: "me",
      marathi: "मे",
    },
    {
      english: "mai",
      marathi: "मै",
    },
    {
      english: "mo",
      marathi: "मो",
    },
    {
      english: "mau",
      marathi: "मौ",
    },
    {
      english: "mam",
      marathi: "मं",
    },
    {
      english: "mah",
      marathi: "मः",
    },
  ],
  [
    {
      english: "ya",
      marathi: " य",
    },
    {
      english: "yaa",
      marathi: "या",
    },
    {
      english: "yi",
      marathi: "यि",
    },
    {
      english: "yee",
      marathi: "यी",
    },
    {
      english: "yu",
      marathi: "यु",
    },
    {
      english: "yoo",
      marathi: "यू",
    },
    {
      english: "ye",
      marathi: "ये",
    },
    {
      english: "yai",
      marathi: "यै",
    },
    {
      english: "yo",
      marathi: "यो",
    },
    {
      english: "yau",
      marathi: "यौ",
    },
    {
      english: "yam",
      marathi: "यं",
    },
    {
      english: "yah",
      marathi: "यः",
    },
  ],
  [
    {
      english: "ra",
      marathi: " र",
    },
    {
      english: "raa",
      marathi: "रा",
    },
    {
      english: "ri",
      marathi: "रि",
    },
    {
      english: "ree",
      marathi: "री",
    },
    {
      english: "ru",
      marathi: "रु",
    },
    {
      english: "roo",
      marathi: "रू",
    },
    {
      english: "re",
      marathi: "रे",
    },
    {
      english: "rai",
      marathi: "रै",
    },
    {
      english: "ro",
      marathi: "रो",
    },
    {
      english: "rau",
      marathi: "रौ",
    },
    {
      english: "ram",
      marathi: "रं",
    },
    {
      english: "rah",
      marathi: "रः",
    },
  ],
  [
    {
      english: "la",
      marathi: " ल",
    },
    {
      english: "laa",
      marathi: "ला",
    },
    {
      english: "li",
      marathi: "लि",
    },
    {
      english: "lee",
      marathi: "ली",
    },
    {
      english: "lu",
      marathi: "लु",
    },
    {
      english: "loo",
      marathi: "लू",
    },
    {
      english: "le",
      marathi: "ले",
    },
    {
      english: "lai",
      marathi: "लै",
    },
    {
      english: "lo",
      marathi: "लो",
    },
    {
      english: "lau",
      marathi: "लौ",
    },
    {
      english: "lam",
      marathi: "लं",
    },
    {
      english: "lah",
      marathi: "लः",
    },
  ],
  [
    {
      english: "va",
      marathi: " व",
    },
    {
      english: "vaa",
      marathi: "वा",
    },
    {
      english: "vi",
      marathi: "वि",
    },
    {
      english: "vee",
      marathi: "वी",
    },
    {
      english: "vu",
      marathi: "वु",
    },
    {
      english: "voo",
      marathi: "वू",
    },
    {
      english: "ve",
      marathi: "वे",
    },
    {
      english: "vai",
      marathi: "वै",
    },
    {
      english: "vo",
      marathi: "वो",
    },
    {
      english: "vau",
      marathi: "वौ",
    },
    {
      english: "vam",
      marathi: "वं",
    },
    {
      english: "vah",
      marathi: "वः",
    },
  ],
  [
    {
      english: "sha",
      marathi: " श",
    },
    {
      english: "shaa",
      marathi: "शा",
    },
    {
      english: "shi",
      marathi: "शि",
    },
    {
      english: "shee",
      marathi: "शी",
    },
    {
      english: "shu",
      marathi: "शु",
    },
    {
      english: "shoo",
      marathi: "शू",
    },
    {
      english: "she",
      marathi: "शे",
    },
    {
      english: "shai",
      marathi: "शै",
    },
    {
      english: "शो",
      marathi: "शो",
    },
    {
      english: "shau",
      marathi: "शौ",
    },
    {
      english: "sham",
      marathi: "शं",
    },
    {
      english: "shah",
      marathi: "शः",
    },
  ],
  [
    {
      english: "sa",
      marathi: " स",
    },
    {
      english: "saa",
      marathi: "सा",
    },
    {
      english: "si",
      marathi: "सि",
    },
    {
      english: "see",
      marathi: "सी",
    },
    {
      english: "su",
      marathi: "सु",
    },
    {
      english: "soo",
      marathi: "सू",
    },
    {
      english: "se",
      marathi: "से",
    },
    {
      english: "sai",
      marathi: "सै",
    },
    {
      english: "so",
      marathi: "सो",
    },
    {
      english: "sau",
      marathi: "सौ",
    },
    {
      english: "sam",
      marathi: "सं",
    },
    {
      english: "sah",
      marathi: "सः",
    },
  ],
  [
    {
      english: "ha",
      marathi: " ह",
    },
    {
      english: "haa",
      marathi: "हा",
    },
    {
      english: "hi",
      marathi: "हि",
    },
    {
      english: "hee",
      marathi: "ही",
    },
    {
      english: "hu",
      marathi: "हु",
    },
    {
      english: "hoo",
      marathi: "हू",
    },
    {
      english: "he",
      marathi: "हे",
    },
    {
      english: "hai",
      marathi: "है",
    },
    {
      english: "ho",
      marathi: "हो",
    },
    {
      english: "hau",
      marathi: "हौ",
    },
    {
      english: "ham",
      marathi: "हं",
    },
    {
      english: "hah",
      marathi: "हः",
    },
  ],
  [
    {
      english: "la",
      marathi: " ळ",
    },
    {
      english: "laa",
      marathi: "ळा",
    },
    {
      english: "li",
      marathi: "ळि",
    },
    {
      english: "lee",
      marathi: "ळी",
    },
    {
      english: "lu",
      marathi: "ळु",
    },
    {
      english: "loo",
      marathi: "ळू",
    },
    {
      english: "le",
      marathi: "ळे",
    },
    {
      english: "lai",
      marathi: "ळै",
    },
    {
      english: "lo",
      marathi: "ळो",
    },
    {
      english: "lou",
      marathi: "ळौ",
    },
    {
      english: "lam",
      marathi: "ळं",
    },
    {
      english: "lah",
      marathi: "ळः",
    },
  ],
  [
    {
      english: "Ksha",
      marathi: " क्ष",
    },
    {
      english: "kshaa",
      marathi: "क्षा",
    },
    {
      english: "kshi",
      marathi: "क्षि",
    },
    {
      english: "kshee",
      marathi: "क्षी",
    },
    {
      english: "kshu",
      marathi: "क्षु",
    },
    {
      english: "kshoo",
      marathi: "क्षू",
    },
    {
      english: "kshe",
      marathi: "क्षे",
    },
    {
      english: "kshai",
      marathi: "क्षै",
    },
    {
      english: "ksho",
      marathi: "क्षो",
    },
    {
      english: "kshau",
      marathi: "क्षौ",
    },
    {
      english: "ksham",
      marathi: "क्षं",
    },
    {
      english: "kshah",
      marathi: "क्षः",
    },
  ],
  [
    {
      english: "dnya",
      marathi: " ज्ञ",
    },
    {
      english: "dnyaa",
      marathi: "ज्ञा",
    },
    {
      english: "Dnyi",
      marathi: "ज्ञि",
    },
    {
      english: "Dnyee",
      marathi: "ज्ञी",
    },
    {
      english: "dnyu",
      marathi: "ज्ञु",
    },
    {
      english: "dnyoo",
      marathi: "ज्ञू",
    },
    {
      english: "dnye",
      marathi: "ज्ञे",
    },
    {
      english: "dnyai",
      marathi: "ज्ञै",
    },
    {
      english: "dnyo",
      marathi: "ज्ञो",
    },
    {
      english: "dnyau",
      marathi: "ज्ञौ",
    },
    {
      english: "dnyam",
      marathi: "ज्ञं",
    },
    {
      english: "dnyah",
      marathi: "ज्ञः",
    },
  ],
];

const allLetersBasic = [
  {
    english: "a",
    marathi: " अ",
  },
  {
    english: "aa",
    marathi: " आ",
  },
  {
    english: "i",
    marathi: " इ",
  },
  {
    english: "ee",
    marathi: " ई",
  },
  {
    english: "u",
    marathi: " उ",
  },
  {
    english: "oo",
    marathi: " ऊ",
  },
  {
    english: "ru",
    marathi: " ऋ",
  },
  {
    english: "e",
    marathi: " ए",
  },
  {
    english: "ai",
    marathi: " ऐ",
  },
  {
    english: "o",
    marathi: " ओ",
  },
  {
    english: "au",
    marathi: " औ",
  },
  {
    english: "am",
    marathi: " अं",
  },
  {
    english: "ah",
    marathi: " अः",
  },
];

const allNumbers = [
  [
    { number: 1, value: "one " },
    { number: 2, value: "two " },
    { number: 3, value: "three " },
    { number: 4, value: "four " },
    { number: 5, value: "five " },
    { number: 6, value: "six " },
    { number: 7, value: "seven " },
    { number: 8, value: "eight " },
    { number: 9, value: "nine " },
    { number: 10, value: "ten " },
  ],
  [
    { number: 11, value: "eleven " },
    { number: 12, value: "twelve " },
    { number: 13, value: "thirteen " },
    { number: 14, value: "fourteen " },
    { number: 15, value: "fifteen " },
    { number: 16, value: "sixteen " },
    { number: 17, value: "seventeen " },
    { number: 18, value: "eighteen " },
    { number: 19, value: "nineteen " },
    { number: 20, value: "twenty " },
  ],
  [
    { number: 21, value: "twenty one " },
    { number: 22, value: "twenty two " },
    { number: 23, value: "twenty three " },
    { number: 24, value: "twenty four " },
    { number: 25, value: "twenty five " },
    { number: 26, value: "twenty six " },
    { number: 27, value: "twenty seven " },
    { number: 28, value: "twenty eight " },
    { number: 29, value: "twenty nine " },
    { number: 30, value: "thirty " },
  ],
  [
    { number: 31, value: "thirty one " },
    { number: 32, value: "thirty two " },
    { number: 33, value: "thirty three " },
    { number: 34, value: "thirty four " },
    { number: 35, value: "thirty five " },
    { number: 36, value: "thirty six " },
    { number: 37, value: "thirty seven " },
    { number: 38, value: "thirty eight " },
    { number: 39, value: "thirty nine " },
    { number: 40, value: "forty " },
  ],
  [
    { number: 41, value: "forty one " },
    { number: 42, value: "forty two " },
    { number: 43, value: "forty three " },
    { number: 44, value: "forty four " },
    { number: 45, value: "forty five " },
    { number: 46, value: "forty six " },
    { number: 47, value: "forty seven " },
    { number: 48, value: "forty eight " },
    { number: 49, value: "forty nine " },
    { number: 50, value: "fifty " },
  ],
  [
    { number: 51, value: "fifty one " },
    { number: 52, value: "fifty two " },
    { number: 53, value: "fifty three " },
    { number: 54, value: "fifty four " },
    { number: 55, value: "fifty five " },
    { number: 56, value: "fifty six " },
    { number: 57, value: "fifty seven " },
    { number: 58, value: "fifty eight " },
    { number: 59, value: "fifty nine " },
    { number: 60, value: "sixty " },
  ],
  [
    { number: 61, value: "sixty one " },
    { number: 62, value: "sixty two " },
    { number: 63, value: "sixty three " },
    { number: 64, value: "sixty four " },
    { number: 65, value: "sixty five " },
    { number: 66, value: "sixty six " },
    { number: 67, value: "sixty seven " },
    { number: 68, value: "sixty eight " },
    { number: 69, value: "sixty nine " },
    { number: 70, value: "seventy " },
  ],
  [
    { number: 71, value: "seventy one " },
    { number: 72, value: "seventy two " },
    { number: 73, value: "seventy three " },
    { number: 74, value: "seventy four " },
    { number: 75, value: "seventy five " },
    { number: 76, value: "seventy six " },
    { number: 77, value: "seventy seven " },
    { number: 78, value: "seventy eight " },
    { number: 79, value: "seventy nine " },
    { number: 80, value: "eighty " },
  ],
  [
    { number: 81, value: "eighty one " },
    { number: 82, value: "eighty two " },
    { number: 83, value: "eighty three " },
    { number: 84, value: "eighty four " },
    { number: 85, value: "eighty five " },
    { number: 86, value: "eighty six " },
    { number: 87, value: "eighty seven " },
    { number: 88, value: "eighty eight " },
    { number: 89, value: "eighty nine " },
    { number: 90, value: "ninety " },
  ],
  [
    { number: 91, value: "ninety one " },
    { number: 92, value: "ninety two " },
    { number: 93, value: "ninety three " },
    { number: 94, value: "ninety four " },
    { number: 95, value: "ninety five " },
    { number: 96, value: "ninety six " },
    { number: 97, value: "ninety seven " },
    { number: 98, value: "ninety eight " },
    { number: 99, value: "ninety nine " },
    { number: 100, value: "one hundred " },
  ],
];

const qa=[
  {
  question: "What is your Name",
  ans: "What is your Name ANS"
},
{
  question: "Father Name",
  ans: "Father Name ANS"
},
{
  question: "Mother Name",
  ans: "Mother Name ANS"
},
{
  question: "Grand Father Name",
  ans: "Grand Father Name ANS"
},

{
  question: "What is your Name",
  ans: "What is your Name ANS"
},


{
  question: "What is your bike number",
  ans: "What is your bike number ANS"
}]

export { allLetters, allLetersBasic, allNumbers, qa};
