import logo from './logo.svg';
import { useState } from 'react';
import allLetters from './constant/allLeters';
import Letters from './components/Letters';

function App() {


  console.log('letters')

  return ( 
    <div className="App">
      <Letters />
    </div>
  );
}

export default App;
